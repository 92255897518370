import { Link } from "gatsby";
import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";

const BackButton = ({ title, link }) => {
  return (
    <Link to={link} className="flex hover:text-secondary items-center my-2">
      <MdKeyboardBackspace size="1.5em" />
      <span className="ml-2 font-semibold">Go back to {title}</span>
    </Link>
  );
};

export default BackButton;
