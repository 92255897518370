import React from "react";

export const QuoteCard = ({ quote, quoteAuthor }) => (
  <div className="md:hidden my-6">
    <p className="text-gray-500 font-semibold text-sm mt-8 italic">"{quote}"</p>
    <p className="text-gray-900 font-bold text-sm mt-2 text-right">
      - {quoteAuthor}
    </p>
  </div>
);
