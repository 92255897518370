import React from "react";
import BackButton from "../back-button";

const FootprintsLayout = ({ children }) => {
  return (
    <div className="max-w-screen-xl mx-auto px-4 md:px-0">
      <BackButton link={"/footprints"} title="Footprints" />
      {children}
    </div>
  );
};

export default FootprintsLayout;
