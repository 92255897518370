import React from "react";

export const DesktopAuthorCard = ({ author, description, isWide, quote }) => {
  const width = isWide ? "md:w-80" : "md:w-64";
  return (
    <aside
      className={`hidden bg-primaryLight md:block md:h-full ${width} md:sticky md:top-20 p-4 rounded-lg shadow-md text-center`}
    >
      <div className="flex flex-col w-full">
        <img
          className="w-32 h-32 object-center object-cover rounded-full self-center"
          alt={`${author.name}`}
          src={author.displayPicture.fixed.src}
        />
        <h4 className="font-heading text-xl font-semibold mt-6">
          {author.name}
        </h4>
        {author.description && (
          <div
            className="text-gray-800 font-medium text-sm mt-4 whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: author.description.childMarkdownRemark.html,
            }}
          ></div>
        )}

        {description && (
          <>
            <hr className="mt-8 border-t-2 border-primary" />
            <p className="text-gray-500 font-semibold text-sm mt-8">
              {description}
            </p>
          </>
        )}

        {quote && quote.content && quote.author && (
          <>
            <hr className="mt-8 border-t-2 border-primary" />
            <p className="text-gray-500 font-semibold text-sm mt-8 italic">
              "{quote.content}"
            </p>
            <p className="text-gray-900 font-semibold text-sm mt-4">
              {quote.author}
            </p>
          </>
        )}
      </div>
    </aside>
  );
};

export const MobileAuthorCard = ({ author }) => {
  return (
    <div className="md:hidden mt-4 w-full bg-primaryLight p-4 rounded-lg shadow-md flex flex-col items-center text-center">
      <div className="w-16 h-16">
        <img
          className="w-full h-full object-cover rounded-full"
          alt={`${author.name}`}
          src={author.displayPicture.fixed.src}
        />
      </div>
      <div className="mt-4">
        <h4 className="font-heading text-xl font-semibold">{author.name}</h4>
        {author.description && (
          <div
            className="text-gray-700 text-sm whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: author.description.childMarkdownRemark.html,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};
