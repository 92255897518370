import { graphql } from 'gatsby';
import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Layout } from '../../components/layout/layout';
import { referencesOptions, textOptions } from '../../util/rich-text';
import {
  DesktopAuthorCard,
  MobileAuthorCard,
} from '../../components/cards/author-card';
import { PageHeading } from '../../components/layout/page-heading';
import FootprintsLayout from '../../components/layout/footprints-layout';
import { QuoteCard } from '../../components/cards/quote-card';

const Article = ({ data }) => {
  const article = data.contentfulArticle;
  return (
    <Layout
      title={article.title}
      description={article.description.description}
      image={article.coverPhoto.fixed.src}
    >
      <FootprintsLayout>
        <div className="flex mt-6">
          <DesktopAuthorCard
            author={article.author}
            quote={{
              content: article?.quote?.quote,
              author: article?.quoteAuthor,
            }}
          />
          <main className="max-w-screen-md md:px-4 md:ml-8">
            <PageHeading title={article.title} />
            <MobileAuthorCard author={article.author} />
            <div className="mt-6">
              {article.content && renderRichText(article.content, textOptions)}
            </div>
            {article.references && (
              <div>
                <h3 className="text-2xl font-bold mt-8 mb-2">References</h3>
                {renderRichText(article.references, referencesOptions)}
              </div>
            )}
            {article.quote && article.quoteAuthor && (
              <div className="md:hidden my-6">
                <QuoteCard
                  quote={article.quote.quote}
                  quoteAuthor={article.quoteAuthor}
                />
              </div>
            )}
          </main>
        </div>
      </FootprintsLayout>
    </Layout>
  );
};

export default Article;

export const query = graphql`
  query ($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            description
            fixed(width: 1200) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
      references {
        raw
      }
      quote {
        quote
      }
      description {
        description
      }
      coverPhoto {
        fixed(width: 1200) {
          width
          height
          src
          srcSet
        }
      }
      quoteAuthor
      author {
        name
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        displayPicture {
          fixed(width: 400) {
            width
            height
            src
            srcSet
          }
        }
      }
    }
  }
`;
